<template>
<div>
   
  <div class="card" v-if="activities">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcının Son 30 günlük Aktiviteleri</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{recordcount}}</b> Aktvivite</span>
      </h3>
    </div>
    <!--end::Header-->

    
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
           <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
               <thead>
                   <tr>
                       <th>Aksiyon</th>
                       <th>Aksiyon Tipi	</th>
                       <th>Tarih</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for="(item, i) in activities" :key="i">
                       <td>{{item.action}}</td>                       
                       <td>{{moment(item.actionDate).format('DD MMMM YYYY HH:MM')}}</td>
                       <td>{{item.userActionType1}}</td>
                   </tr>
               </tbody>
           </table>

           <paging 
            v-if="recordcount>0" 
            :page="page" 
            :pagelength="pagelength" 
            :recordcount="recordcount" 
            @next="next" 
            @prev="prev"
            @gotoPage="gotoPage($event)"
            />

      </div>
    </div>
  </div>
</div>
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import labels from "@/core/config/labels.js"

import moment from 'moment'
import api from '@/core/services/ApiService'
import Paging from '@/components/BD/Paging.vue';
export default {
  components: { Paging },
  name: "Gorusmeler",
  props:['profile'],
  data(){
      return {
          calls:null,
          recordcount:0,
          callTypes:labels.callTypes,
          callStatusses:labels.callStatusses,
          pagelength:30,
          page:1,
      }
  },
  watch:{
      page:function(){
        this.getActivities();
      }
    },

  methods:{
      moment(date){
          return moment(date)
      },

      next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      },

      getActivities(){
         if(this.profile){
           let today = new Date();
          let last30Day = new Date(new Date().setDate(today.getDate() - 30));
          
          let activityType = 0; // 0 Hepsi, 1: Hemen, 2: Randevulu
          let pagelength = this.pagelength;
          let page = this.page;
          let start_date = moment(last30Day).format('YYYY-MM-DD');
          let end_date = moment(today).format('YYYY-MM-DD');
  

           let url = '/User/ListUserActions/User/'+activityType+'/'+this.profile.userId+'/'+start_date+'/'+end_date+'/'+pagelength+'/'+page;

             api.get(url).then((res)=>{
                this.activities = res.data.userActions;
                this.recordcount = res.data.rowCount;
            });
         }
          
      }


  },
  mounted(){
      setCurrentPageBreadcrumbs("Aktiviteler", ["Kullanıcılar", this.profile.name+' '+this.profile.surname]);
      this.getActivities();
    
  }
  
}
</script>
